<template>
    <div class="main">
        <Title >企业荣誉</Title>
        <div class="box1">
          <div v-for="(itemd,index) in data" :key="index" class="flex wrap">
            <div class="col3" v-for="item in itemd" :key="item[0]">
              <ImagePreview :height="'90%'" :width="'90%'" class="hvr-push img" :src="require('../../assets/Goin/honor/'+item[0])" :srcList="[require('../../assets/Goin/honor/'+item[0])]">
                  <p class="img-text">{{item[1]}}</p>
              </ImagePreview>
            </div>
          </div>
        </div>
        <!-- <More @more = "more"></More> -->
    </div>
</template>
<script>
import img1 from "@/assets/Goin/honor/honor1.png"
import img2 from "@/assets/Goin/honor/honor2.png"
import img3 from "@/assets/Goin/honor/honor3.png"
import img4 from "@/assets/Goin/honor/honor4.png"
import img5 from "@/assets/Goin/honor/honor5.png"
import img6 from "@/assets/Goin/honor/honor6.png"
// import img7 from "@/assets/Goin/honor/honor7.png"
export default {
    data(){
        return {
            img1:img1,
            img2:img2,
            img3:img3,
            img4:img4,
            img5:img5,
            img6:img6,
            // img7:img7,
            imgList1:[img1],
            imgList2:[img2],
            imgList3:[img3],
            imgList4:[img4],
            imgList5:[img5],
            imgList6:[img6],
            // imgList7:[img7],
            data:[
              [
                ['1-1.jpg','高新技术企业'],
                ['1-2.jpg','司法鉴定许可证'],
              ],
              [
                ['2-1.jpg','发明专利证书'],
                ['2-2.jpg','发明专利证书'],
                ['2-3.jpg','发明专利证书'],
              ],
              [
                ['3-1.jpg','教学科研实践基地'],
                ['3-2.jpg','智慧物证与物联网技术联合实验室'],
                ['3-3.jpg','物证技术联合研发中心'],
                ['3-4.jpg','司法鉴定战略合作单位'],
              ],
              [
                ['4-1.jpg','CMA资质认定证书'],
                // ['4-2.jpg','CNAS能力验证结果通知'],
                // ['4-3.jpg','CNAS能力验证结果通知'],
                // ['4-4.jpg','CNAS能力验证结果通知'],
                ['4-5.jpg','CMA资质认定证书'],
              ],
            ]
        }
    },
    methods:{
        more(){
            console.log('more')
        }
    }
}
</script>
<style src="../../layout.css" scoped></style>
<style scoped>
.main{
    margin:0 auto;
    width:80vw;
    margin-bottom:2rem;
    max-width:1600px;
}
.img{
    /* width:33.3%; */
    max-width: 350px;
}
.img-text{
    margin: 2rem;
}
.box1{
    /* display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: self-end; */
}
@media screen and (max-width:337px){
    .box1{
        justify-content:center;
    }
    .img{
        min-width: 200px;
    }
}
</style>